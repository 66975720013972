export const uploadFile = async (file?: File): Promise<string> => {
  if (!file) {
    throw new Error("No file provided");
  }

  const response = await fetch("/api/media/upload", {
    method: "POST",
    body: JSON.stringify({ requireSignedURLs: false }),
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();

  const formData: any = new FormData();
  formData.append("file", file);

  const uploadResponse = await fetch(data?.uploadURL, {
    method: "POST",
    body: formData,
  });

  const {
    result: { variants },
  } = await uploadResponse.json();

  const avatarURL: string = variants?.[0];

  return avatarURL;
};
