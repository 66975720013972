export interface ILoginMessages {
  [key: string]: string;
}

export const loginMessages: ILoginMessages = {
  magicLinkSuccess: "A sign in link has been sent to your email address.",
  magicLinkError: "An error occurred while sending the sign in link.",
  emailRequired: "Email is required",
  unvalidEmail:
    "There is no account associated with this email address. Make sure you're using the right email or contact our support team.",
};
