
const KEY = process.env.IMAGE_SIGNATURE_KEY as string;
const EXPIRATION = 60 * 60 * 24; // 1 day

const bufferToHex = (buffer: any) =>
  [...new Uint8Array(buffer)]
    .map((x) => x.toString(16).padStart(2, "0"))
    .join("");

export async function generateSignedUrl(url: URL) {
  const encoder = new TextEncoder();
  const secretKeyData = encoder.encode(KEY);
  const key = await crypto.subtle.importKey(
    "raw",
    secretKeyData,
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign"],
  );

  const expiry = Math.floor(Date.now() / 1000) + EXPIRATION;
  url.searchParams.set("exp", expiry.toString());

  const stringToSign = url.pathname + "?" + url.searchParams.toString();

  const mac = await crypto.subtle.sign(
    "HMAC",
    key,
    encoder.encode(stringToSign),
  );
  const sig = bufferToHex(new Uint8Array(mac).buffer);

  url.searchParams.set("sig", sig);

  return url.toString();
}
