export function getUniqueStrings(array: string[]): string[] {
  // Create a new set to store the unique strings.
  const set: Set<string> = new Set();

  // Iterate over the input array and add each string to the set.
  for (const string of array) {
    set.add(string);
  }

  // Convert the set to an array and return it.
  return Array.from(set);
}
