export interface IErrors {
  [key: string]: string;
}

export const errors: IErrors = {
  Signin: "Try signing with a different account.",
  OAuthSignin: "Try signing with a different account.",
  OAuthCallback: "Try signing with a different account.",
  OAuthCreateAccount: "Try signing with a different account.",
  EmailCreateAccount: "Try signing with a different account.",
  Callback: "Sign in failed. Try again.",
  OAuthAccountNotLinked:
    "To confirm your identity, sign in with the same account you used originally.",
  EmailSignin: "Check your email address.",
  CredentialsSignin:
    "Sign in failed. Check the details you provided are correct.",
  default: "Unable to sign in.",
  accountSuspended: "This account is suspended. Please contact admin.",
};

export const DEFAULT_NETWORK_ERROR_MESSAGE = "Unexpected error. Try again.";
export const UPLOAD_ERROR_MESSAGE = "Unable to upload file. Try again.";
