export function getAuthBaseURL(env: string) {
  switch (env) {
    case "main":
    case "adil-permissions":
    case "staging":
      return process.env.AUTH0_BASE_URL;

    default:
      return `https://${process.env.VERCEL_URL}`;
  }
}
