import { DEFAULT_NETWORK_ERROR_MESSAGE } from "@src/constants/errors";
import axios, { AxiosRequestConfig } from "axios";
import { signOut } from "next-auth/react";

export const fetcher = async (
  url: string,
  options: AxiosRequestConfig = {},
  localRequest: boolean = false,
) => {
  let res;

  try {
    res = await axios(
      localRequest ? url : process.env.NEXT_PUBLIC_APP_BASE_URL + url,
      {
        method: "GET",
        ...options,
        headers: {
          ...options?.headers,
          "Content-Type": "application/json",
          "Ws-Enabled": "true",
        },
        withCredentials: true,
      },
    );

    if (res.status < 200 || res.status >= 300) {
      throw new Error(res.statusText || DEFAULT_NETWORK_ERROR_MESSAGE);
    }

    return res.data;
  } catch (error: any) {
    if (error?.response?.status === 401 && typeof window !== "undefined") {
      signOut();
      return;
    } else {
      throw {
        message: error?.response?.data?.detail || DEFAULT_NETWORK_ERROR_MESSAGE,
        ...error,
      };
    }
  }
};
