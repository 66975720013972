import { marked } from 'marked';

export function isMarkdownValue(value: string): boolean {
  if (!value || typeof value !== "string") {
    return false;
  }

  const tokenTypes: string[] = [];
  
  // https://marked.js.org/using_pro#tokenizer
  marked(value, {
    walkTokens: (token) => {
      tokenTypes.push(token.type);
    },
  });

  const isMarkdown = [
    'space',
    'code',
    'fences',
    'heading',
    'hr',
    'link',
    'blockquote',
    'list',
    'html',
    'def',
    'table',
    'lheading',
    'escape',
    'tag',
    'reflink',
    'strong',
    'codespan',
    'url',
  ].some((tokenType) => tokenTypes.includes(tokenType));

  return isMarkdown;
}