export const permissionList = {
  CREATE_ORGANIZATIONS: "platform_organization_create",
  EDIT_ORGANIZATION: "organization_update",
  DELETE_ORGANIZATION: "organization_delete",
  LIST_AVAILABLE_ROLES: "list_available_roles",
  LIST_ORG_MEMBERS: "organization_member_list",
  UPDATE_ORG_MEMBER_ROLE: "organization_member_update_roles",
  TRANSFER_ORG_OWNERSHIP: "organization_transfer_ownership",
  RETRIEVE_ORG: "organization_retrieve",
  SUSPEND_ORG_MEMBER: "organization_member_suspend",
  INVITE_ORG_MEMBER: "organization_invite_member",
  LIST_PERMISSIONS: "list_permissions",
  CANCEL_ORG_INVITE: "organization_invite_cancel",
  RESEND_ORG_INVITE: "organization_invite_resend",
  LIST_ORG_AUDIT_LOGS: "organization_list_audit_logs",
  RESTORE_TEAMS: "organization_team_restore",
  CREATE_TEAM: "organization_team_create",
  LIST_TEAMS: "organization_team_list",
  DELETE_TEAM: "team_delete",
  EDIT_TEAM: "team_update",
  LIST_TEAM_MEMBERS: "team_member_list",
  UPDATE_TEAM_MEMBER_ROLE: "team_member_update_roles",
  SUSPEND_TEAM_MEMBER: "team_member_suspend",
  INVITE_TEAM_MEMBER: "team_invite_member",
  CANCEL_TEAM_INVITE: "team_invite_cancel",
  LIST_TEAM_AUDIT_LOGS: "team_list_audit_logs",
  RESEND_TEAM_INVITE: "team_invite_resend",
  LIST_PROJECTS: "team_project_list",
  CREATE_PROJECT: "team_project_create",
  DUPLICATE_PROJECT: "team_project_duplicate",
  RESTORE_DELETED_PROJECTS: "team_project_restore",
  RETRIEVE_PROJECT: "project_retrieve",
  PUBLISH_PROJECT: "project_publish",
  EDIT_PROJECT: "project_update",
  DELETE_PROJECT: "project_delete",
  LIST_PROJECT_MEMBERS: "project_member_list",
  UPDATE_PROJECT_MEMBER_ROLE: "project_member_update_roles",
  SUSPEND_PROJECT_MEMBER: "project_member_suspend",
  INVITE_PROJECT_MEMBER: "project_invite_member",
  CANCEL_PROJECT_INVITE: "project_invite_cancel",
  LIST_PROJECT_AUDIT_LOGS: "project_list_audit_logs",
  RESEND_PROJECT_INVITE: "project_invite_resend",
  REORDER_PROJECT_ELEMENTS: "project_reorder_elements",
  CREATE_REPORT: "project_reporter_report_create",
  LIST_REPORT: "project_reporter_report_list",
  RETRIEVE_REPORT: "project_reporter_report_retrieve",
  REORDER_REPORT: "project_reporter_report_reorder",
  CREATE_REPORTER_SEGMENT: "project_reporter_segment_create",
  LIST_REPORTER_SEGMENT: "project_reporter_segment_list",
  DELETE_REPORTER_SEGMENT: "project_reporter_segment_delete",
  DUPLICATE_REPORTER_SEGMENT: "project_reporter_segment_duplicate",
  RETRIEVE_REPORTER_SEGMENT: "project_reporter_segment_retrieve",
  UPDATE_REPORTER_SEGMENT: "project_reporter_segment_update",
  REORDER_REPORTER_SEGMENT: "project_reporter_segment_reorder",
  LIST_REPORTER_PAGE: "project_reporter_page_lists",
  SHARE_REPORT: "reporter_report_share_access",
  UPDATE_REPORT: "reporter_report_update",
  DELETE_REPORT: "reporter_report_delete",
  DUPLICATE_REPORT: "reporter_report_duplicate",
  CREATE_REPORTER_PAGE: "reporter_page_create",
  RETRIEVE_REPORTER_PAGE: "reporter_page_retrieve",
  UPDATE_REPORTER_PAGE: "reporter_page_update",
  DELETE_REPORTER_PAGE: "reporter_page_delete",
  DUPLICATE_REPORTER_PAGE: "reporter_page_duplicate",
  REORDER_REPORTER_PAGE: "reporter_page_reorder",
  CREATE_REPORTER_TABLE: "reporter_table_create",
  LIST_REPORTER_TABLE: "reporter_table_list",
  RETRIEVE_REPORTER_TABLE: "reporter_table_retrieve",
  DELETE_REPORTER_TABLE: "reporter_table_delete",
  UPDATE_REPORTER_TABLE: "reporter_table_update",
  REORDER_REPORTER_TABLE: "reporter_table_reorder",
  DUPLICATE_REPORTER_TABLE: "reporter_table_duplicate",
  REMOVE_REPORTER_ACCESS: "reporter_report_remove_access",
  REORDER_UPDATE_ROLES: "reporter_report_update_roles",
};
