export const getLabelinLanguage = (
  labels: { language: string; text: string }[] = [],
  language?: string,
) => {
  const label = labels?.find((e) => e.language === language);
  return label?.text || "";
};

export const getURLinLanguage = (
  labels: { language: string; url: string }[] = [],
  language?: string,
) => {
  if (!labels || !language) return "";
  
  const match = labels?.find((e) => e.language === language);
  return match?.url || "";
};
