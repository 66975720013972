import { ReactElement } from "react";
import { AccountMenuOptions, ProjectIcon, SettingsIcon } from "ui";
import { permissionList } from "./permissionList";
interface RoutesProps {
  [key: string]: string;
}

interface AccountMenuProps {
  href: string;
  label: string;
  icon: ReactElement;
  permittedRoles: string[];
}

const ROUTES: RoutesProps = {
  "/": "",
  "/profile/settings": "Profile settings",
  "/workspace/settings": "Workspace settings",
  "/projects": "Projects",
  "/workspace/new": "New workspace",
  "/team/[team_id]/settings": "Team settings",
  "/project/[project_id]/settings": "Project settings",
  default: "/projects",
  newWorkspace: "/workspace/new",
};

const NAV_MENU_OPTIONS: AccountMenuProps[] = [
  {
    href: "/projects",
    label: "Projects",
    icon: <ProjectIcon />,
    permittedRoles: ["owner", "admin", "member"],
  },
  {
    href: "/workspace/settings",
    label: "Settings",
    icon: <SettingsIcon />,
    permittedRoles: ["owner", "admin"],
  },
];

const ACCOUNT_MENU_OPTIONS: AccountMenuOptions[] = [
  {
    href: "/profile/settings",
    label: "Profile settings",
    icon: "/settings-icon.svg",
    iconProps: {
      solidBackground: true,
    },
  },
  // {
  //   disabled: true,
  // },
  {
    label: "Logout",
    icon: "/logout-icon.svg",
    handler: "logout",
  },
];

const APP_PERMITTED_ROUTES: { [key: string]: string[] } = {
  // "/path": "required permission(s)",
  "/workspace/new": [permissionList.CREATE_ORGANIZATIONS],
  "/workspace/invite": [
    permissionList.CREATE_ORGANIZATIONS,
    permissionList.INVITE_ORG_MEMBER,
  ],
  "/workspace/settings": [permissionList.EDIT_ORGANIZATION],
  "/projects": [],
  "/profile/settings": [],
  "/profile/setup": [],
  "/invitations/[invitation_token]/accept": [],
  "/team/new": [permissionList.CREATE_TEAM],
  "/team/[team_id]": [],
  "/team/[team_id]/settings": [permissionList.EDIT_TEAM],
  "/team/[team_id]/projects": [],
  "/project/[project_id]/settings": [permissionList.EDIT_PROJECT],
};

export { ROUTES, ACCOUNT_MENU_OPTIONS, NAV_MENU_OPTIONS, APP_PERMITTED_ROUTES };
